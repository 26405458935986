import React from 'react';
import FindOutMoreCardComponent from '../components/common/FindOutMoreCardComponent';
import HeroCardComponent from '../components/common/HeroCardComponent';
import ContactContainer from '../components/contact/ContactContainer.component';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
import ProductsSummaryList from './productsSummary';
import {
    BreadcrumbsItem
} from 'react-breadcrumbs-dynamic'

class ProductsPageComponent extends React.Component {
    heroDetails = {
        title: {
            primary: 'Products',
            secondary: '',
        },
        subTitle: 'Over the last 10 years, we have developed a suite of artificial intelligence-driven applications for data harvesting, production and analysis that provide an unrivalled source of regulatory intelligence.',
        description: 'We use this regulatory data to enable the Legal and RegTech industry to provide compliance and regulatory requirement solutions to clients across a broad range of sectors.',
        highlights: [
            "RegTech industry providers can access real-time horizon scanning data from thousands of regulatory bodies delivered via RegsBase.",
            "Financial institutions looking to streamline compliance with hundreds of frequently changing governance, risk and trading rules can meet the challenge head on with RegsDeck.",
            "Compliance officers across multiple sectors can receive daily email updates about changes affecting their industry with our cost-effective RegsAlert."
        ],
        imageURL: "/assets/products_bg.png",
    }
    contactDetails = {
        subTitle: "By combining artificial intelligence-powered technology and vector tagging, we have created a complete regulatory content monitoring tool to empower your organisation.",
        title: "If you would like to request a demo or find out more about The Hub, please get in touch."
    }

    render() {
        return <div>
            <BreadcrumbsItem to='/products'>Products</BreadcrumbsItem>
            <Navbar />
            <HeroCardComponent
                title={this.heroDetails.title.primary}
                titleSecondary={this.heroDetails.title.titleSecondary}
                subTitle={this.heroDetails.subTitle}
                highlights={this.heroDetails.highlights}
                bgImageUrl={this.heroDetails.imageURL}
                description={this.heroDetails.description}
            />
            <div className="grid grid-cols-1 mt-8 mb-8 gap-1 pro_page">
                {ProductsSummaryList.map((product, index) => <FindOutMoreCardComponent key={product.title} data={product} index={index} imageStyle={{ height: '330px' }} noJustify={true} />)}
            </div>
            <ContactContainer contactDetails={this.contactDetails} />
            <FooterContainer />
        </div>
    }
}
export default ProductsPageComponent;