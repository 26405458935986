import React from "react";
import NavbarActions from "./NavbarActions.component";
import NavbarCTAs from "./NavbarCTAs.component";
import NavbarLogo from "./NavbarLogo.component";
import { FaBars } from 'react-icons/fa';
export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="relative flex items-center justify-between px-6 py-3">
        <NavbarLogo />
        <NavbarActions navbarOpen={navbarOpen} />
        <NavbarCTAs />
        <button
          className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <FaBars size={24} className="text-primary" />
        </button>
      </nav>
    </>
  );
}
