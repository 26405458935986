import React from 'react';
import FindOutMoreCardComponent from '../components/common/FindOutMoreCardComponent';
import ContactContainer from '../components/contact/ContactContainer.component';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
import ProductsSummaryList from '../product/productsSummary';
import {
    BreadcrumbsItem
} from 'react-breadcrumbs-dynamic'

class ContactPageComponent extends React.Component {
    contactDetails = {
        subTitle: "By combining artificial intelligence-powered technology and vector tagging, we have created a complete regulatory content monitoring tool to empower your organisation.",
        title: "If you would like to request a demo or find out more about The Hub, please get in touch."
    }
    render() {
        return <div>
            <BreadcrumbsItem to='/contact'>Contact</BreadcrumbsItem>
            <Navbar />
            <ContactContainer contactDetails={this.contactDetails} />
            <div className="grid grid-cols-1 mt-8 mb-8 gap-1">
                {ProductsSummaryList.map((product, index) => <FindOutMoreCardComponent key={product.title} data={product} index={index} imageStyle={{ height: '330px' }} />)}
            </div>
            <FooterContainer />
        </div>
    }
}
export default ContactPageComponent;