import React from 'react';
import FindOutMoreCardComponent from '../components/common/FindOutMoreCardComponent';
import HeroCardComponent from '../components/common/HeroCardComponent';
import ContactContainer from '../components/contact/ContactContainer.component';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
import sectorsSummary from './sectorsSummary';
import {
    BreadcrumbsItem
} from 'react-breadcrumbs-dynamic'
class SectorsPageComponent extends React.Component {
    heroDetails = {
        title: {
            primary: 'Our Sectors',
            secondary: '',
        },
        subTitle: 'The Hub.ai enables the RegTech industry through our wholly owned suite of AI powered applications which continuously harvest the key data for your sector, providing you with a peerless source of regulatory knowledge.',
        description: 'This regulatory data that we gather from institutions around the globe facilitates the provision of compliance and regulatory requirement solutions to clients across an extensive range of sectors.',
        highlights: [
            "All industries have standards and regulations that organisations must comply with. We work with some of the biggest financial institutions across all spheres of financial services, a sector for which anti-money laundering compliance and cybersecurity regulation are a high priority.",
            "Pharmaceutical companies need to stay on top of the regulatory landscape more than ever, as the need to protect their supply chains becomes increasingly important.",
            "Other sectors for which we help clients make sense of often complex and challenging requirements include GRC regulatory compliance and ESG reporting.",
        ],
        imageURL: "/assets/sectors_bg.png",
    }
    contactDetails = {
        subTitle: "By combining artificial intelligence-powered technology and vector tagging, we have created a complete regulatory content monitoring tool to empower your organisation.",
        title: "If you would like to request a demo or find out more about The Hub, please get in touch."
    }

    render() {
        return <div>
            <BreadcrumbsItem to='/sectors'>Sectors</BreadcrumbsItem>
            <Navbar />
            <HeroCardComponent
                title={this.heroDetails.title.primary}
                titleSecondary={this.heroDetails.title.secondary}
                subTitle={this.heroDetails.subTitle}
                highlights={this.heroDetails.highlights}
                bgImageUrl={this.heroDetails.imageURL}
                description={this.heroDetails.description}
            />
            <div className="grid grid-cols-1 mt-8 mb-8 gap-1 pro_page">
                {sectorsSummary.map((sector, index) => <FindOutMoreCardComponent key={sector.title} data={sector} index={index} noJustify={true} />)}
            </div>
            <ContactContainer contactDetails={this.contactDetails} />
            <FooterContainer />
        </div>
    }
}
export default SectorsPageComponent;