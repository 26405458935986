const sectors = [
    {
        title: "Financial Services",
        description: "Amid huge volumes of legal obligations and unrelenting regulatory change, the financial services sector faces an ongoing challenge to ensure compliance. Find out more about The Hub.ai's solutions.",
        url: "/sectors/financialServices",
        imageURL: "/assets/finserv.png"
    },
    {
        title: "GRC",
        description: "Having a single supplier for all GRC regulatory updates puts you firmly in control of industry regulations and standards compliance requirements. The Hub.ai's bespoke API's and alerts will ensure you are always up to date.",
        url: "/sectors/grc",
        imageURL: "/assets/grc.png"
    },
    {
        title: "Pharma",
        description: "Staying on top of the regulatory landscape is ever-increasingly important for Pharmaceutical companies. Find out more about how The Hub.ai's data services can help safeguard supply chains from counterfeiting and theft.",
        url: "/sectors/pharma",
        imageURL: "/assets/pharma.png"
    },
    {
        title: "Cyber",
        description: "Having one provider to identify and deliver cyber regulatory changes relevant to your sector allows you to reduce risk and keep your assets safe. The Hub.ai's API's and alerts will ensure you are always up to date with the latest compliance.",
        url: "/sectors/cyber",
        imageURL: "/assets/cyber.png"
    },
    {
        title: "AML",
        description: "The Hub.ai's 360-degree view of global and regional anti-money laundering regulations can provide you with the knowledge required to stay on top of potential issues before they occur. Find out more.",
        url: "/sectors/aml",
        imageURL: "/assets/kyc.png"
    },
    {
        title: "ESG",
        description: "ESG reporting is ever-more complex and demanding as legislators in jurisdictions around the globe are prompted to introduce further ESG regulations. Find out more about The Hub.ai's solutions.",
        url: "/sectors/esg",
        imageURL: "/assets/esg.png"
    }
]

export default sectors;