import './TitilliumFonts.css'
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import SectorsPageComponent from './sector/SectorsPageComponent';
import HomePageComponent from './home/HomePageComponent';
import SectorPageComponent from './sector/SectorPageComponent';
import ProductsPageComponent from './product/ProductsPageComponent';
import AboutPageComponent from './about/AboutPageComponent';
import ContactPageComponent from './contact/ContactPageComponent';
import ProductPageComponent from './product/ProductPageComponent';
import {
  BreadcrumbsItem
} from 'react-breadcrumbs-dynamic'
import ModernSlaveryStatementPageComponent from './modern-slavery-statement/ModernSlaveryStatementPageComponent';
import DataProtectionPageComponent from './data-protection/dataProtectionPageComponent';

function App() {
  return (
    <Router>
      <BreadcrumbsItem to='/'>Home</BreadcrumbsItem>
      <Switch>
        <Route path="/products/:id" component={ProductPageComponent} />
        <Route path="/sectors/:id" component={SectorPageComponent} />
        <Route path="/home" component={HomePageComponent} />
        <Route path="/about" component={AboutPageComponent} />
        <Route path="/contact" component={ContactPageComponent} />
        <Route path="/sectors" component={SectorsPageComponent} />
        <Route path="/products" component={ProductsPageComponent} />
        <Route path="/modern-slavery-statement" component={ModernSlaveryStatementPageComponent} />
        <Route path="/privacy-policy" component={DataProtectionPageComponent} />
        <Route path="/">
          <Redirect to={'/home'} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
