const productsSummary = [
    {
        id: "regsbase",
        type: 'dark',
        title: 'RegsBase',
        description: "Real-time, global, horizon scanning of over 3,500 regulatory bodies, all fully indexed and delivered via an extensive set of bespoke Hub.ai APIs.",
        imageURL: "/assets/product1.png",
        url: "/products/regsbase"
    },
    {
        id: "regsdeck",
        type: 'light',
        title: 'RegsDeck',
        description: "A fast, easy-to-use interface delivering real-time content from across the entire compliance and regulatory landscape.",
        imageURL: "/assets/product2.png",
        url: "/products/regsdeck"
    },
    {
        id: "regsalerts",
        type: 'dark',
        title: 'RegsAlerts',
        description: "Daily alerts delivered by email enabling you to stay on top of compliance across any industry or sector.",
        imageURL: "/assets/product3.png",
        url: "/products/regsalerts"
    }
]
export default productsSummary;