import React from "react";

export default function SectionTitle({ title }) {
  return (
    <div 
      className="text-gray-500 px-5 md:px-12 text-4xl py-2">
      {title}
    </div>
  );
}
