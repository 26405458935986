import React from "react";

export default function FooterLogo() {
  return (
    <a
      className="text-sm font-bold leading-relaxed inline-block pr-12 whitespace-nowrap uppercase text-white"
      href="/home"
    >
      <img className="self-center" src="/assets/logo/c-logo.png" alt="TheHub logo" />
    </a>
  );
}
