import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function NavbarActions({ navbarOpen }) {

  const history = useHistory();
  const location = useLocation();

  let onClickNavMenuItem = (_link) => {
    history.push(_link);
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }

  return (
    <div
      className={
        "lg:flex flex-grow py-5" +
        (navbarOpen ? " flex" : " hidden") + " justify-center"
      }
      id="example-navbar-danger"
    >
      <ul className="lg:flex flex-col mx-auto lg:flex-row justify-center list-none lg:ml-auto">
        <li className="navbar-header mb-5 block lg:hidden">
          <img className="self-center" src="/assets/logo/c-logo.png" alt="TheHub logo" />
        </li>
        <li className="nav-item">
          <span
            className="px-3 items-center text-gray-500 text-sm leading-snug hover:opacity-75 cursor-pointer"
            onClick={() => { onClickNavMenuItem('/home') }}
          >
            <span className={location.pathname === "/home" ? "ml-2 text-gray-800 font-bold" : "ml-2"}>Home</span>
          </span>
        </li>
        <li className="nav-item">
          <span
            className="px-3 items-center text-gray-500 text-sm leading-snug hover:opacity-75 cursor-pointer"
            onClick={() => { onClickNavMenuItem('/products') }}
          >
            <span className={location.pathname === "/products" ? "ml-2 text-gray-800 font-bold" : "ml-2"}>Products</span>
          </span>
        </li>
        <li className="nav-item">
          <span
            className="px-3 items-center text-gray-500 text-sm leading-snug hover:opacity-75 cursor-pointer"
            onClick={() => { onClickNavMenuItem('/sectors') }}
          >
            <span className={location.pathname === "/sectors" ? "ml-2 text-gray-800 font-bold" : "ml-2"}>Sectors</span>
          </span>
        </li>
        <li className="nav-item">
          <span
            className="px-3 items-center text-gray-500 text-sm leading-snug hover:opacity-75 cursor-pointer"
            onClick={() => { onClickNavMenuItem('/about') }}
          >
            <span className={location.pathname === "/about" ? "ml-2 text-gray-800 font-bold" : "ml-2"}>About</span>
          </span>
        </li>
        <li className="nav-item">
          <span
            className="px-3 items-center text-gray-500 text-sm leading-snug hover:opacity-75 cursor-pointer"
            onClick={() => { onClickNavMenuItem('/contact') }}
          >
            <span className={location.pathname === "/contact" ? "ml-2 text-gray-800 font-bold" : "ml-2"}>Contact</span>
          </span>
        </li>
      </ul>
    </div>

  );
}
