import React from 'react';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
class ModernSlaveryStatementPageComponent extends React.Component {
    render() {
        return <div>
            <Navbar />
            <section className="bg-gray-100 text-green-900 relative hero_sec">
                <div
                    className="hero-image bg-right-bottom bg-cover flex text-white">
                    <div className="relative container md:px-8 px-3 pt-4 pb-8 flex items-end z-10">
                        <div className="content float-left pt-4 pb-8 md:px-5 my-5">
                            <div className="heading mb-3 text-46px md:text-52px text-gray-500">Modern Slavery Statement&nbsp;
                            </div>

                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">Introduction</div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    This statement is made pursuant to section 54(1) of the Modern Slavery Act 2015 and constitutes the Group's slavery and human trafficking statement for the financial year ending December 2017 and has been approved by the Board of THE HUB ARTIFICIAL INTELLIGENCE LIMITED (“The Hub”)
                                </p>
                                <p className="pb-2">
                                    Our vision is to be the world’s most trusted staffing company, trusted by our people, our customers and investors in equal measure. This vision, together with our shared signature practices, gives clear guidance on the behaviours and actions we expect from all the people and organisations that work with The Hub. We choose to engage and partner with like-minded organisations because they recognise people should be treated with dignity and respect.
                                </p>
                            </div>

                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">Our Organisation</div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    The Hub is a global provider of IT services to a wide range of clients covering a broad range of industry sectors. We have over 100+ employees across the UK and India and have its registered office in England and Wales as a limited company.
                                </p>
                                <p className="pb-2">
                                    The Hub do not believe there is any place in today’s world for slavery or human trafficking. We are committed to continually improving our practices to combat slavery and human trafficking and to encourage the same high standards from our business partners.
                                </p>
                            </div>

                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">Policies and Training</div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    In order to mitigate and manage our risk the following activities have been undertaken in 2017:-
                                </p>
                                <div className="pb-4">
                                    <ul className="md:text-18px text-14px list-disc pl-8">
                                        <li className="pb-1">Our Modern Slavery policy was issued to all employees, who acknowledged and confirmed they had read and understood the same.</li>
                                        <li className="pb-1">We published mandatory Modern Slavery training to all existing employees, and this will be refreshed annually.</li>
                                        <li className="pb-1">We have advised employees on how they can report any concerns they may have associated with Modern Slavery and reminded all employees of our confidential whistleblowing helpline if they wish to make an anonymous report.</li>
                                        <li className="pb-1">All new starters were required to complete Modern Slavery training within one month of joining.</li>
                                        <li className="pb-1">All our branches received at least one compliance audit during which pre-employment checks, including eligibility to work, references, qualifications and proof of national insurance numbers, were reviewed.</li>
                                        <li className="pb-1">We also performed random checks within our UK businesses to identify where agency workers may be sharing bank accounts, addresses or telephone numbers as this can be an indication of illegal or unethical behaviour.</li>
                                        <li className="pb-1">We have not used or accepted forced, bonded or involuntary prison labour or child labour; nor will we hold onto our workers’ identity papers or knowingly work with businesses that do.</li>
                                        <li className="pb-1">No breaches of the Modern Slavery Act were reported during 2017.</li>
                                    </ul>
                                </div>
                                <p className="pb-2">
                                    We review our policies and procedures regularly, not only to ensure they remain relevant, but also to assess our progress.
                                </p>
                                <p className="pb-4">
                                    This statement applies to The Hub Ltd and all its subsidiaries.
                                </p>
                                <div style={{ 'line-height': '1.1' }}>
                                    {/* <div className="text font-bold sm:block lg:w-1/2 md:text-20px text-16px text-gray-500">Jonathan Anthony</div> */}
                                    <div className="text font-bold sm:block lg:w-1/2 md:text-20px text-16px text-gray-500">Group CEO</div>
                                    <div className="text font-bold sm:block lg:w-1/2 md:text-20px text-16px text-gray-500">THE HUB ARTIFICIAL INTELLIGENCE LIMITED</div>
                                    {/* <div className="text font-bold sm:block lg:w-1/2 md:text-20px text-16px text-gray-500">Date: 30 June 2018</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterContainer />
        </div>
    }
}
export default ModernSlaveryStatementPageComponent;