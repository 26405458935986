import React from 'react';
import ContactContainer from '../components/contact/ContactContainer.component';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
import FindOutMoreCardComponent from '../components/common/FindOutMoreCardComponent';
import HeroCardComponent from '../components/common/HeroCardComponent';
import ProductsList from './products';
import ProductsSummaryList from './productsSummary';
import SectionTitle from '../components/common/SectionTitle.component';
import {
    BreadcrumbsItem
} from 'react-breadcrumbs-dynamic'
class ProductPageComponent extends React.Component {

    getProduct() {
        let product = {};
        let id = this.props.match.params.id;
        let matchingProducts = ProductsList.filter((el) => {
            return el.id === id
        });
        if (matchingProducts && matchingProducts.length) {
            product = matchingProducts[0];
        }
        return product;
    }

    getOherProducts(_product) {
        return ProductsSummaryList.filter((pl) => pl.id !== _product.id);
    }

    contactDetails = {
        subTitle: "By combining artificial intelligence-powered technology and vector tagging, we have created a complete regulatory content monitoring tool to empower your organisation.",
        title: "If you would like to request a demo or find out more about The Hub, please get in touch."
    }

    getHeroDetails(_product) {
        return {
            title: {
                primary: _product.title,
                secondary: '',
            },
            subTitle: _product.subTitle,
            description: _product.description,
            imageURL: "/assets/product_bg.png",
        }
    }


    render() {
        let product = this.getProduct();
        let otherProducts = this.getOherProducts(product);
        product.features.map((feature) => feature.imageURL = product.imageURL);
        let heroDetails = this.getHeroDetails(product)
        return <div>
            <BreadcrumbsItem to='/products'>Products</BreadcrumbsItem>
            <BreadcrumbsItem to={`/products/${product.id}`}>{product.title}</BreadcrumbsItem>
            <Navbar />
            <HeroCardComponent
                title={heroDetails.title.primary}
                titleSecondary={heroDetails.title.titleSecondary}
                subTitle={heroDetails.subTitle}
                highlights={heroDetails.highlights}
                bgImageUrl={heroDetails.imageURL}
                description={heroDetails.description}
            />
            <div className="grid grid-cols-1 mt-8 mb-8 gap-1 pro_page">
                {
                    product.features.map((_feature, index) => {
                        return <FindOutMoreCardComponent key={_feature.title} data={_feature}
                            index={index} highlights={_feature.highlights} hideFoundOutMoreButton={true} imageStyle={{ height: '330px' }} noJustify={true} />
                    })
                }
            </div>
            <div className="mb-8">
                <SectionTitle title={"Our other Products"} />
                <div className="grid grid-cols-1 md:grid-cols-2 mt-8 mb-8 gap-1">
                    {
                        otherProducts.map((_feature, index) => {
                            return <FindOutMoreCardComponent key={_feature.title} data={_feature}
                                index={index} highlights={_feature.highlights} hideImage={true} noDarkLight={true} />
                        })
                    }
                </div>
            </div>
            <ContactContainer contactDetails={this.contactDetails} />
            <FooterContainer />
        </div >
    }

}
export default ProductPageComponent;