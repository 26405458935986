import React from "react";

export default function HeroCardComponent({ title, titleSecondary, subTitle, description, highlights, bgImageUrl }) {
    return (
        <>
            <section className="text-green-900 relative hero_sec">
                <div
                    className="hero-image bg-right-bottom bg-cover flex text-white "
                    style={{ backgroundImage: `url(${bgImageUrl})` }}>
                    <div className="relative container md:px-8 px-3 pt-4 pb-8 flex items-end z-10">
                        <div className="content float-left pt-4 pb-8 md:px-5 my-5">
                            <div className="heading mb-3 text-46px md:text-52px">{title}&nbsp;
                                <span className="font-light">{titleSecondary}</span>
                            </div>
                            <div className="text leading-normal sm:block lg:w-1/2 md:text-20px text-16px pb-2">{subTitle}</div>
                            <p className="text leading-normal sm:block lg:w-1/2 md:text-18px  text-14px pb-2">{description}</p>
                            {
                                highlights && highlights.length ?
                                    <div>
                                        <ul className="md:text-18px  text-14px list-disc pl-8">
                                            {highlights.map((item, i) => <li key={i}>{item}</li>)}
                                        </ul>
                                    </div>
                                    : <></>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
