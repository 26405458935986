const products = [
    {
        id: "regsbase",
        type: 'dark',
        title: 'RegsBase',
        subTitle: "RegsBase is our industry-leading, artificial intelligence-powered platform, providing an  authoritative source of indexed, worldwide regulatory data which is unmatched in the market.",
        description: "RegsBase combines advanced technology and real-time horizon scanning of thousands of regulatory bodies to provide a constantly updated service offering solutions to the challenges of international regulatory compliance. Delivered via an extensive set of APIs, RegsBase can be customised to serve the unique requirements of your business and compliance needs.",
        imageURL: "/assets/product1.png",
        url: "/product/regsbase",
        "features": [
            {
                "title": "Main features",
                // "description": "As the premium providers of regulatory intelligence in RegTech, we harvest data from more than 3,500 sources, unrivalled in the industry. RegsBase has a global reach across multiple sectors, providing your business with the most up-to-date data from the leading sources. RegsBase technology fully scans regulatory body data to the deepest levels, so we can provide unparalleled real-time insights.",
                "highlights": [
                    "As the premium providers of regulatory intelligence in RegTech, we harvest data from more than 3,500 sources, unrivalled in the industry.",
                    "RegsBase has a global reach across multiple sectors, providing your business with the most up-to-date data from the leading sources.",
                    "RegsBase technology fully scans regulatory body data to the deepest levels, so we can provide unparalleled real-time insights. "
                ]
            },
            {
                "title": "Main benefits",
                // "description": "We monitor our extensive range of RegsBase sources each hour of every day – 365 days a year. We deliver bespoke regulatory data to clients immediately, enabling real-time compliance with no lags. The RegsBase artificial intelligence-driven suite of applications is wholly owned, allowing us to be agile, cost-effective and flexible in delivering bespoke information to our clients. Our systems can  respond rapidly to deliver tailored regulatory intelligence to cater for your sector-specific business or compliance requirements.",
                "highlights": [
                    "We monitor our extensive range of RegsBase sources each hour of every day – 365 days a year.",
                    "We deliver bespoke regulatory data to clients immediately, enabling real-time compliance with no lags.",
                    "The RegsBase artificial intelligence-driven suite of applications is wholly owned, allowing us to be agile, cost-effective and flexible in delivering bespoke information to our clients.",
                    "Our systems can  respond rapidly to deliver tailored regulatory intelligence to cater for your sector-specific business or compliance requirements."
                ]
            }
        ]
    },
    {
        id: "regsdeck",
        type: 'light',
        title: 'RegsDeck',
        subTitle: "RegsDeck is our bespoke regulatory user interface. Quick and easy-to-use, it is fully customisable to each user’s requirements, delivering sector-specific regulatory intelligence to suit your research and analysis needs.",
        description: "Powered by a peerlessly comprehensive source of global regulatory data generated by real-time scanning of more than 3,500 regulatory bodies, RegsDeck enables streamlined compliance amid a constantly changing regulatory landscape. The Hub.ai RegsDesk dashboard can be edited and amended to suit your changing requirements and is covered by our ongoing support service.",
        imageURL: "/assets/product2.png",
        url: "/product/regsdeck",
        "features": [
            {
                "title": "Main features",
                // "description": "A bespoke, user-friendly dashboard created by The Hub.ai which delivers the regulatory knowledge required to facilitate efficient and cost-effective compliance solutions. Driven by RegsBase, with real-time horizon scanning of thousands of regulatory bodies, RegsDesk provides an unrivalled and constantly refreshed source of new and updated regulatory data. An easy-to-use interface presenting comprehensively indexed and tagged content, fully searchable by sector and topic, for scanning or deep dive as required. Different people within teams can access different dashboards delivering relevant sector-specific data.",
                "highlights": [
                    "A bespoke, user-friendly dashboard created by The Hub.ai which delivers the regulatory knowledge required to facilitate efficient and cost-effective compliance solutions.",
                    "Driven by RegsBase, with real-time horizon scanning of thousands of regulatory bodies, RegsDesk provides an unrivalled and constantly refreshed source of new and updated regulatory data.",
                    "An easy-to-use interface presenting comprehensively indexed and tagged content, fully searchable by sector and topic, for scanning or deep dive as required.",
                    "Different people within teams can access different dashboards delivering relevant sector-specific data."
                ]
            },
            {
                "title": "Main benefits",
                // "description": "RegsDeck is a very flexible tool which can be extended, focused and updated as part of The Hub.ai’s ongoing support service. Search tags can be reviewed so that content is precisely tailored to ensure that all your new and updated regulatory content is delivered in one place. Document scanning allows you to quickly locate and assess new and updated content in large documents – changes are highlighted in red, updates and new text are highlighted in green",
                "highlights": [
                    "RegsDeck is a very flexible tool which can be extended, focused and updated as part of The Hub.ai’s ongoing support service.",
                    "Search tags can be reviewed so that content is precisely tailored to ensure that all your new and updated regulatory content is delivered in one place.",
                    "Document scanning allows you to quickly locate and assess new and updated content in large documents – changes are highlighted in red, updates and new text are highlighted in green.",
                ]
            }
        ]
    },
    {
        id: "regsalerts",
        type: 'dark',
        title: 'RegsAlerts',
        subTitle: "RegsAlert is a free service providing an insightful daily email update of curated regulatory content from The Hub.ai ",
        description: "Drawn from our unrivalled source of global regulatory data, RegsAlert delivers a free daily update of carefully curated content direct to your inbox, helping you to stay on top of compliance requirements across any industry or sector. Simply register with an email address, select the sector and jurisdiction most relevant to your business and receive your RegsAlert update at 9am each day.",
        imageURL: "/assets/product3.png",
        url: "/product/regsalerts",
        "features": [
            {
                "title": "Main features",
                "description": "",
                "highlights": [
                    "A curated overview of 20 regulatory sector updates relevant to your business from our database of more than 3,500 sources.",
                    "Free access to The Hub.ai’s unique artificial intelligence-powered regulatory data platform."
                ]
            },
            {
                "title": "Main benefits",
                "description": "",
                "highlights": [
                    "A daily update of the latest regulatory news delivered to you by email. ",
                    "Deeper regulatory data insights thanks to our regular, full deep-dive scans across all platforms. "
                ]
            }
        ]
    }
]
export default products;