import React from 'react';
import ContactContainer from '../components/contact/ContactContainer.component';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
import sectorsList from './sectors';
import SectionTitle from '../components/common/SectionTitle.component';
import SectorSubscriptionFormComponent from './SectorSubscriptionFormComponent';
import FindOutMoreCardComponent from '../components/common/FindOutMoreCardComponent';
import HeroCardComponent from '../components/common/HeroCardComponent';
import ProductsSummaryList from '../product/productsSummary';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
class SectorPageComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { allSectorsRecentArticles: [] };
    }

    getAllSectorsRecentArticles = async () => {
        let _id = this.props.match.params.id;
        if (_id === 'financialServices')
            _id = 'Factoring'
        let response = await fetch('/allSectorsRecentArticles/' + _id);
        response = await response.json();
        this.setState({ allSectorsRecentArticles: response.data });
    }

    componentDidMount = async () => {
        this.getAllSectorsRecentArticles();
    }

    getSector() {
        let sector = {};
        let id = this.props.match.params.id;
        console.log('Get Sector is called', id);
        let matchingSectors = sectorsList.filter((el) => {
            return el.id === id
        });
        if (matchingSectors && matchingSectors.length) {
            sector = matchingSectors[0];
        }
        return sector;
    }

    getHeroDetails(_sector) {
        return {
            title: {
                primary: _sector.title,
            },
            subTitle: _sector.subTitle,
            description: _sector.description,
            imageURL: _sector.imageURL,
        }
    }

    contactDetails = {
        subTitle: "By combining artificial intelligence-powered technology and vector tagging, we have created a complete regulatory content monitoring tool to empower your organisation.",
        title: "If you would like to request a demo or find out more about The Hub, please get in touch."
    }

    getDomain(_url) {
        return _url ? new URL(_url).origin : '#'
    }

    render() {
        let sector = this.getSector();
        let heroDetails = this.getHeroDetails(sector);
        return <div>
            <BreadcrumbsItem to='/sectors'>Sectors</BreadcrumbsItem>
            <BreadcrumbsItem to={`/sectors/${sector.id}`}>{sector.title}</BreadcrumbsItem>
            <Navbar />
            <HeroCardComponent
                title={heroDetails.title.primary}
                titleSecondary={heroDetails.title.titleSecondary}
                subTitle={heroDetails.subTitle}
                highlights={heroDetails.highlights}
                bgImageUrl={heroDetails.imageURL}
                description={heroDetails.description}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 mt-8 mb-8 gap-1">
                <div className="bg-primary text-white">
                    <div className="px-12 py-4 pt-10">
                        {this.state?.allSectorsRecentArticles?.map((data) => {
                            return <div key={data?.title}>
                                <div className="text leading-normal font-semibold sm:block md:text-20px text-16px pb-2">
                                    {data?.title}
                                </div>
                                <div className="text leading-normal sm:block  md:text-18px text-14px pb-2">
                                    {
                                        data?.articles?.map((article) => {
                                            return <div key={article.title} className="pb-3">
                                                <p className="pb-1">
                                                    - <a className="underline" href={article.source} target="_blank" rel="noreferrer">{`${article.title.substring(0, 55)}...`}</a>
                                                </p>
                                                <p className="px-2 md:text-14px text-12px">
                                                    {`${article.summary.substring(0, 150)}...`}
                                                </p>
                                                <div className="px-2 md:text-14px text-12px">Published: {new Date(article.publication_date).toLocaleDateString("en-UK")}</div>
                                                <div className="px-2 md:text-14px text-12px pb-1">
                                                    Source:&nbsp;
                                                    <a className="underline break-all md:break-normal" href={this.getDomain(article.parent_url)} target="_blank" rel="noreferrer">
                                                        {article.issuer}
                                                    </a>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {
                                        !data?.articles?.length ?
                                            <p className="pt-4">
                                                No articles found.
                                            </p> : <></>
                                    }
                                </div>
                            </div>
                        })}
                        <p className="pt-4">
                            * For daily email alert please fill out the Subscribe form.
                        </p>
                    </div>
                </div>
                <div className="bg-gray-100 text-gray-500 w-full px-6 lg:px-12 py-8">
                    <div className="mt-2">
                        <SectionTitle title={"Subscribe to our Free Daily Alert"} />
                        <p className="text-gray-500 px-12 py-2">
                        Daily alerts is a part of our RegsAlerts product. To create custom alerts across 3000+ regulatory sites, <a className="text-blue-500" href="/contact">contact us</a>.
                        </p>
                    </div>
                    <SectorSubscriptionFormComponent />
                </div>
            </div>
            <div className="mb-8">
                <SectionTitle title={"Our Products"} />
                <div className="grid grid-cols-1 md:grid-cols-3  gap-1">
                    {
                        ProductsSummaryList.map((_feature, index) => {
                            return <FindOutMoreCardComponent key={_feature.title} data={_feature}
                                index={index} highlights={_feature.highlights} hideImage={true} />
                        })
                    }
                </div>
            </div>
            <ContactContainer contactDetails={this.contactDetails} />
            <FooterContainer />
        </div>
    }

}
export default SectorPageComponent;