import React from "react";
import Sector from "./Sector.component";
import sectorList from './sectors';

export default function SectorListContainer() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-0.5 sector_img">
      {sectorList.map(s => <Sector key={s.title} sector={s} />)}
    </div>
  );
}
