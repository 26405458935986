import React from "react";

export default function FooterAddress() {
  return (
    <div className="md:px-5 footer_nav">
      <p>130 Wood Street</p>
      <p>London</p>
      <p>EC2V 6DL</p>
    </div>
  );
}