import React from "react";
import { FaLinkedin } from 'react-icons/fa';

export default function FooterSocialInfo() {
  return (
    <div className="flex flex-col justify-between">
      <div>
        <ul className="flex flex-row">
          <li className="pr-4">
            <a href="https://www.linkedin.com/company/thehubai/" target="_blank" rel="noreferrer">
              <FaLinkedin size={48} className="text-primary" />
            </a>
          </li>
        </ul>
      </div>
      <div className="text-lg">
        © TheHub.ai, 2023. All Rights Reserved
      </div>
    </div>
  );
}
