import React from 'react';
import FindOutMoreCardComponent from '../components/common/FindOutMoreCardComponent';
import HeroCardComponent from '../components/common/HeroCardComponent';
import ContactContainer from '../components/contact/ContactContainer.component';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
import ProductsSummaryList from '../product/productsSummary';
import {
    BreadcrumbsItem
} from 'react-breadcrumbs-dynamic'

class AboutPageComponent extends React.Component {
    heroDetails = {
        title: {
            primary: 'About',
            secondary: 'TheHub.ai',
        },
        subTitle: 'We are a team of technology and regulatory knowledge experts. As data providers to the RegTech industry, we understand why and how artificial intelligence-driven technology can be practically applied to manage regulatory change and reduce risk, while saving time and costs.',
        description: 'Since 2015, we have dedicated ourselves to advancing technology for practical application within the legal and regulatory industry. We have developed horizon scanning, indexing and analysis tools which enable streamlined delivery of new or updated regulatory content.',
        imageURL: "/assets/about_bg.png",
        highlights: [
            "We apply artificial intelligence techniques to enable institutions to source, organise and utilise unstructured information from across the web. Our innovative applications monitor and help implement legislation, regulations and industry guidance that allows firms to mitigate their legal risks. ",
            "We empower Legal and Regulatory technology companies with our core application that enables clients to simplify complex and ever-changing compliance needs in an efficient and cost-effective manner. We continue to develop leading products and solutions for our customers in the Regtech industry."
        ]
    }

    contactDetails = {
        subTitle: "By combining artificial intelligence-powered technology and vector tagging, we have created a complete regulatory content monitoring tool to empower your organisation.",
        title: "If you would like to request a demo or find out more about The Hub, please get in touch."
    }
    render() {
        return <div>
            <BreadcrumbsItem to='/about'>About</BreadcrumbsItem>
            <Navbar />
            <HeroCardComponent
                title={this.heroDetails.title.primary}
                titleSecondary={this.heroDetails.title.secondary}
                subTitle={this.heroDetails.subTitle}
                highlights={this.heroDetails.highlights}
                bgImageUrl={this.heroDetails.imageURL}
                description={this.heroDetails.description}
            />
            <div className="grid grid-cols-1 mt-8 mb-8 gap-1 pro_page">
                {ProductsSummaryList.map((product, index) => <FindOutMoreCardComponent key={product.title} data={product} index={index} imageStyle={{ height: '330px' }} />)}
            </div>
            <ContactContainer contactDetails={this.contactDetails} />
            <FooterContainer />
        </div>
    }
}
export default AboutPageComponent;