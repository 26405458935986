import React from "react";
import { useHistory } from "react-router-dom";

export default function NavbarCTAs() {
  const history = useHistory();
  let onClickNavMenuItem = (_link) => {
    history.push(_link);
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  return (
    <div
      className="md:flex hidden flex-col self-start justify-items-end pt-8"
    >
      <div className="flex flex-row justify-items-end">
        {/* <button onClick={() => { onClickNavMenuItem('/contact') }} className="bg-primary hover:bg-primary-hover text-white py-0 px-5 mr-5 rounded-sm text-sm">
          CUSTOMER LOGIN
        </button> */}
        <button onClick={() => { onClickNavMenuItem('/contact') }} className="bg-primary hover:bg-primary-hover text-white py-0 px-5 rounded-sm text-sm w-full">
          REQUEST A DEMO
        </button>
      </div>
      <div className="flex justify-end mt-2">
        <span className="mr-3 text-gray-500 text-lg">+44 20 7097 4753</span>
        <span className="text-gray-500 text-lg">info@thehub.ai</span>
      </div>
    </div>

  );
}
