import React from "react";

export default function NavbarLogo() {
  return (
    <div className="w-auto relative flex justify-between lg:static lg:block lg:justify-start md:py-5">
      <a
        className="text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase text-white"
        href="/home"
      >
        <img className="self-center" src="/assets/logo/c-logo.png" alt="TheHub logo" />
      </a>
    </div>
  );
}
