import React from "react";
import { useHistory } from "react-router-dom";

export default function Sector({ sector }) {
  const containerClass = `flex flex-col justify-between relative text-white`;
  const btnClass = `px-8 rounded bg-white text-gray-500`;
  const history = useHistory();
  let navigateTo = (_data) => {
    history.push(_data.url);
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  return (
    <div className={containerClass}>
      <img className="rounded shadow-md" src={sector.imageURL} alt=""></img>
      <div className="absolute px-4 lg:px-12 py-4 w-full h-full flex pt-10">
        <div className="flex flex-col">
          <div className="text-4xl pb-4">{sector.title}</div>
          <div>
            <div className="text-lg pb-4">{sector.description}</div>
            <div className="pb-6">
              <button className={btnClass} onClick={() => navigateTo(sector)} >
                FIND OUT MORE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
