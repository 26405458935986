import React from "react";
import ContactForm from "./ContactForm.component";
import ContactInfo from "./ContactInfo.component";

export default function ContactContainer({ onContactFormSubmit, contactDetails }) {
  return (
    <section className="flex flex-wrap bg-primary justify-center hero_sec">
      <div className="w-full lg:w-1/2 px-6 py-8">
        <ContactInfo title={contactDetails?.title} subTitle={contactDetails?.subTitle} />
      </div>
      <div className="w-full lg:w-1/2 px-6 lg:px-12 py-8">
        <ContactForm onContactFormSubmit={onContactFormSubmit} />
      </div>
    </section>
  );
}
