const sectors = [
    {
        "id": "financialServices",
        "url": "/sector/financialServices",
        "imageURL": "/assets/sectors_fs_desk.png",
        "title": "Financial Services",
        "subTitle": "The financial services sector faces an immense ongoing challenge to ensure compliance, amid huge volumes of legal obligations and unrelenting regulatory change.",
        description: "With this challenge in mind, we applied machine learning to replace inefficient legacy and manual processes. Utilising artificial intelligence-powered technology to monitor hundreds of financial services regulatory bodies, we identify all key topics, industry terms and entities that are mentioned within their content. We deliver the specific regulatory intelligence you need, in real time, from all the major financial regulatory institutions around the globe.",
        "highlights": [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            "Fusce in blandit gravida massa, nibh volutpat ante.",
            "Aenean justo, feugiat fames eleifend commodo gravida et nulla.",
            "Sollicitudin viverra elementum cras vestibulum."
        ],
        "features": [
            {
                "title": "Sector features",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis.",
                "highlights": [
                    "Cursus sit faucibus lorem velit.",
                    "Nunc, eget cursus sapien lacus vel posuere cursus id.",
                    "Tortor tortor ullamcorper id consequat eu.",
                    "Adipiscing pellentesque malesuada tellus id elit faucibus eget facilisis.",
                    "Cursus sit faucibus lorem velit."
                ]
            },
        ]
    },
    {
        "id": "grc",
        "url": "/sector/grc",
        "imageURL": "/assets/sectors_grc_desk.png",
        "title": "GRC",
        "subTitle": "All industries have regulations and standards that companies must comply with, while also documenting their compliance.",
        description: "Legislations are complex and there can be many requirements. Governance of organisational operations is essential. Having a single supplier for all your GRC regulatory updates puts you firmly in control. Our artificial intelligence-powered applications enable straightforward compliance requirement management by transforming real-time data from multiple sources into actionable regulatory knowledge.",
        "highlights": [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            "Fusce in blandit gravida massa, nibh volutpat ante.",
            "Aenean justo, feugiat fames eleifend commodo gravida et nulla.",
            "Sollicitudin viverra elementum cras vestibulum."
        ],
        "features": [
            {
                "title": "Sector features",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis.",
                "highlights": [
                    "Cursus sit faucibus lorem velit.",
                    "Nunc, eget cursus sapien lacus vel posuere cursus id.",
                    "Tortor tortor ullamcorper id consequat eu.",
                    "Adipiscing pellentesque malesuada tellus id elit faucibus eget facilisis.",
                    "Cursus sit faucibus lorem velit."
                ]
            }
        ]
    },
    {
        "id": "pharma",
        "url": "/sector/pharma",
        "imageURL": "/assets/sectors_pharma_desk.png",
        "title": "Pharma",
        "subTitle": "With the demands placed on pharmaceutical companies to safeguard their supply chain from counterfeiting, diversion and theft, the need to stay on top of the pharmaceutical regulatory landscape has never been more important.",
        description: "Gaining access to all the information required in one place brings huge cost benefits to pharmaceutical companies worldwide. Our industry-leading platform can deliver real-time updates on topics specific to your organisation’s needs to enable it to stay fully compliant and up-to-date with critical pharmaceutical regulatory requirements.",
        "highlights": [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            "Fusce in blandit gravida massa, nibh volutpat ante.",
            "Aenean justo, feugiat fames eleifend commodo gravida et nulla.",
            "Sollicitudin viverra elementum cras vestibulum."
        ],
        "features": [
            {
                "title": "Sector features",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis.",
                "highlights": [
                    "Cursus sit faucibus lorem velit.",
                    "Nunc, eget cursus sapien lacus vel posuere cursus id.",
                    "Tortor tortor ullamcorper id consequat eu.",
                    "Adipiscing pellentesque malesuada tellus id elit faucibus eget facilisis.",
                    "Cursus sit faucibus lorem velit."
                ]
            },
        ]
    },
    {
        "id": "cyber",
        "url": "/sector/cyber",
        "imageURL": "/assets/sectors_cyber_desk.png",
        "title": "Cyber",
        "subTitle": "Cybersecurity is crucial when faced with the increasing threat of attacks on companies in financial services and other sectors.",
        description: "Monitoring cyber-related regulatory change requires time and resources as thousands of new regulations and updates to existing rules are published daily. Having one provider to identify and deliver the specific cyber regulatory changes that are relevant to your sector allows you to reduce risk and keep your assets safe. Our industry-leading platform delivers real-time regulatory updates relevant to your company’s needs enabling a direct route to fully up-to-date compliance.",
        "highlights": [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            "Fusce in blandit gravida massa, nibh volutpat ante.",
            "Aenean justo, feugiat fames eleifend commodo gravida et nulla.",
            "Sollicitudin viverra elementum cras vestibulum."
        ],
        "features": [
            {
                "title": "Sector features",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis.",
                "highlights": [
                    "Cursus sit faucibus lorem velit.",
                    "Nunc, eget cursus sapien lacus vel posuere cursus id.",
                    "Tortor tortor ullamcorper id consequat eu.",
                    "Adipiscing pellentesque malesuada tellus id elit faucibus eget facilisis.",
                    "Cursus sit faucibus lorem velit."
                ]
            }
        ]
    },
    {
        "id": "aml",
        "url": "/sector/aml",
        "imageURL": "/assets/sectors_kyc_desk.png",
        "title": "AML",
        "subTitle": "Anti-money laundering compliance represents efforts to prevent financial crime via multiple global and regional regulations.",
        description: "Anti-money laundering software frequently lacks the actual regulatory content that details how financial institutions can comply with legal requirements. Having a complete 360-degree view can help identify money laundering and financial crime issues that map to new and existing rules, providing clients with the knowledge required to stay on top of potential issues before they occur. Using artificial intelligence-driven applications to monitor hundreds of regulatory bodies sites, we identify all featured key terms and topics and deliver the specific regulatory intelligence you need from the world’s major financial regulatory institutions.",
        "highlights": [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            "Fusce in blandit gravida massa, nibh volutpat ante.",
            "Aenean justo, feugiat fames eleifend commodo gravida et nulla.",
            "Sollicitudin viverra elementum cras vestibulum."
        ],
        "features": [
            {
                "title": "Sector features",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis.",
                "highlights": [
                    "Cursus sit faucibus lorem velit.",
                    "Nunc, eget cursus sapien lacus vel posuere cursus id.",
                    "Tortor tortor ullamcorper id consequat eu.",
                    "Adipiscing pellentesque malesuada tellus id elit faucibus eget facilisis.",
                    "Cursus sit faucibus lorem velit."
                ]
            }
        ]
    },
    {
        "id": "esg",
        "url": "/sector/esg",
        "imageURL": "/assets/esg.png",
        "title": "ESG",
        "subTitle": "ESG reporting is ever-more complex and demanding as legislators in jurisdictions around the globe are prompted to introduce further ESG regulations.",
        description: "As public awareness of the need to act responsibly in order to meet sustainability goals increases, legislators in jurisdictions around the globe are prompted to introduce ESG regulations. Consequently, ESG reporting has become ever-more complex and demanding, particularly for global organisations that report to multiple frameworks. Having one provider to deliver all new and updated regulatory requirements to your organisation allows you to streamline the ESG reporting process, saving both time and costs.",
        "highlights": [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            "Fusce in blandit gravida massa, nibh volutpat ante.",
            "Aenean justo, feugiat fames eleifend commodo gravida et nulla.",
            "Sollicitudin viverra elementum cras vestibulum."
        ],
        "features": [
            {
                "title": "Sector features",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices ac facilisis nunc pellentesque imperdiet nunc ultrices nibh nunc. Ac leo, egestas tristique tincidunt tortor erat bibendum. Commodo at vitae faucibus quis.",
                "highlights": [
                    "Cursus sit faucibus lorem velit.",
                    "Nunc, eget cursus sapien lacus vel posuere cursus id.",
                    "Tortor tortor ullamcorper id consequat eu.",
                    "Adipiscing pellentesque malesuada tellus id elit faucibus eget facilisis.",
                    "Cursus sit faucibus lorem velit."
                ]
            }
        ]
    }
]

export default sectors;