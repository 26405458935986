import React from "react";
import { FaSquareFull } from 'react-icons/fa';
import { useHistory, useLocation } from "react-router-dom";

export default function FooterNavigation() {
  const history = useHistory();
  const location = useLocation();
  let onClickNavMenuItem = (_link) => {
    history.push(_link);
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  return (
    <ul className="list-none px-2 pr-8 footer_nav">
      <li className={location.pathname === "/home" ? "cursor-pointer text-gray-800 font-semibold" : "cursor-pointer"} onClick={() => { onClickNavMenuItem('/home') }} ><FaSquareFull size={6} className="inline-block mr-3" />Home</li>
      <li className={location.pathname === "/products" ? "cursor-pointer text-gray-800 font-semibold" : "cursor-pointer"} onClick={() => { onClickNavMenuItem('/products') }} ><FaSquareFull size={6} className="inline-block mr-3" />Products</li>
      <li className={location.pathname === "/about" ? "cursor-pointer text-gray-800 font-semibold" : "cursor-pointer"} onClick={() => { onClickNavMenuItem('/about') }} ><FaSquareFull size={6} className="inline-block mr-3" />About</li>
      <li className={location.pathname === "/contact" ? "cursor-pointer text-gray-800 font-semibold" : "cursor-pointer"} onClick={() => { onClickNavMenuItem('/contact') }} ><FaSquareFull size={6} className="inline-block mr-3" />Contact</li>
      <li className={location.pathname === "/privacy-policy" ? "cursor-pointer text-gray-800 font-semibold" : "cursor-pointer"} onClick={() => { onClickNavMenuItem('/privacy-policy') }} ><FaSquareFull size={6} className="inline-block mr-3" />Privacy Policy</li>
      <li className={location.pathname === "/modern-slavery-statement" ? "cursor-pointer text-gray-800 font-semibold" : "cursor-pointer"} onClick={() => { onClickNavMenuItem('/modern-slavery-statement') }}><FaSquareFull size={6} className="inline-block mr-3" />Modern Slavery Statement</li>
    </ul>
  );
}
