import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
const KEY = process.env.REACT_APP_reCAPTCHA_KEY;

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      formDirty: false,
      isCaptchaVerified: false,
      recaptchVisible: true,
      loader: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateEmail(emailId) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailId);
  }

  validatePhoneNumber(phone) {
    return /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/i.test(phone)
  }

  validateForm() {
    let invalid = false;
    let fields = this.state.fields;
    let errors = {};
    this.setState({ formDirty: true });
    if (!fields.firstName) {
      errors["firstName"] = "First Name is required.";
      invalid = true;
    } else if (fields.firstName && fields.firstName.length < 3) {
      errors["firstName"] = "First Name should be atleast 3 character long."
      invalid = true;
    }
    if (!fields.lastName) {
      errors["lastName"] = "Last Name is required.";
      invalid = true;
    } else if (fields.lastName && fields.lastName.length < 3) {
      errors["lastName"] = "Last Name should be atleast 3 character long."
      invalid = true;
    }
    if (!this.state.isCaptchaVerified) {
      errors["captcha"] = "Invalid captcha.";
      invalid = true;
    }
    if (!fields.email_id) {
      errors["email_id"] = "Email is required.";
      invalid = true;
    } else if (fields.email_id && fields.email_id.length > 0 && !this.validateEmail(fields.email_id)) {
      errors["email_id"] = "Invalid Email."
      invalid = true;
    }
    if (fields.phone && fields.phone.length > 0 && !this.validatePhoneNumber(fields.phone)) {
      errors["phone"] = "Invalid Phone."
      invalid = true;
    }
    if (!this.state.isCaptchaVerified) {
      invalid = true;
    }
    if (!fields.message) {
      errors["message"] = "Message is required.";
      invalid = true;
    }
    this.setState({ errors });
    return invalid;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ fields: { ...this.state.fields, ...{ [name]: value } } }, () => {
      if (this.state.formDirty) {
        this.validateForm();
      }
    })
  }

  resetAlert() {
    setTimeout(() => {
      this.setState({ alert: {} })
    }, 10000)
  }

  resetCaptch() {
    setTimeout(() => {
      this.setState({ recaptchVisible: true, isCaptchaVerified: false })
    })
  }

  reloadForm() {
    this.resetAlert();
    let fields = {
      firstName: '',
      lastName: '',
      email_id: '',
      phone: '',
      message: ''
    }
    this.setState({ fields: fields, formDirty: false, recaptchVisible: false });
    this.resetCaptch();
  }

  async submitContactForm(formData) {
    let alert = {
      msg: '',
      class: 'bg-red-100 border border-red-400 text-red-700'
    }
    try {
      let res = await fetch('/contactUs', {
        method: 'post',
        body: JSON.stringify(formData.fields),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      res = await res.json();
      alert.msg = res.message;
      if (!res.error) {
        alert.class = 'bg-green-100 border border-green-400 text-green-700';
        this.reloadForm();
      }
      this.setState({ alert: alert, loader: false })
    } catch (error) {
      alert.msg = error.message
      this.setState({ alert: alert, loader: false })
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loader: true });
    let response = await fetch('/verifyCaptcha', {
      method: 'post',
      body: JSON.stringify({ token: this.state.captchaToken }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    response = await response.json();
    if (response && response.success) {
      let formInvalid = this.validateForm();
      if (!formInvalid) {
        let formData = { ...this.state };
        delete formData.sectors;
        delete formData.validation;
        this.submitContactForm(formData);
      }
    } else {
      this.setState({ loader: false });
    }
  }

  onCaptchChange(_token) {
    this.setState({ captcha: true, isCaptchaVerified: _token ? true : false, captchaToken: _token }, () => {
      if (this.state.formDirty) {
        this.validateForm();
      }
    })
  }

  isSubmit() {
    return this.state.isCaptchaVerified;
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} id="sectorSubsForm">
        <div className="px-3 lg:px-8 py-4">
          <div className="mt-1 block mb-5">
            <input type="text" className="w-full text-lg border-0" placeholder="First Name" value={this.state.fields["firstName"]} name="firstName" onChange={this.handleChange} />
            <span className="text-red-600 text-xs">{this.state?.errors?.firstName}</span>
          </div>

          <div className="mt-1 block mb-5">
            <input type="text" className="w-full text-lg border-0" placeholder="Last Name" value={this.state.fields["lastName"]} name="lastName" onChange={this.handleChange} />
            <span className="text-red-600 text-xs">{this.state?.errors?.lastName}</span>
          </div>

          <div className="mt-1 block mb-5">
            <input type="text" className="w-full text-lg border-0" placeholder="Email" value={this.state.fields["email_id"]} name="email_id" onChange={this.handleChange} />
            <span className="text-red-600 text-xs">{this.state?.errors?.email_id}</span>
          </div>

          <div className="mt-1 block mb-5">
            <input type="number" className="w-full text-lg border-0" placeholder="Phone" value={this.state.fields["phone"]} name="phone" onChange={this.handleChange} />
            <span className="text-red-600 text-xs">{this.state?.errors?.phone}</span>
          </div>

          <div className="mt-1 block mb-5">
            <textarea id="message" rows="4" className="w-full text-lg border-0" placeholder="Message" value={this.state.fields["message"]} name="message" onChange={this.handleChange} />
            <span className="text-red-600 text-xs">{this.state?.errors?.message}</span>
          </div>

          {/* <div className="mt-1 block mb-5">
            <GoogleReCaptcha onVerify={this.handleVerify.bind(this)} />
            <span className="text-red-600 text-xs">{this.state?.errors?.captcha}</span>
          </div> */}

          {
            this.state.recaptchVisible ?
              <div className="mt-1 block mb-5">
                <ReCAPTCHA size="normal"
                  sitekey={KEY}
                  onChange={token => { this.onCaptchChange(token) }}
                />
                <span className="text-red-600 text-xs">{this.state?.errors?.captcha}</span>
              </div> : <></>
          }

          <div className="pb-5">
            <button disabled={!this.isSubmit() || this.state.loader} className={this.isSubmit() ? "py-3 px-20 rounded-sm bg-gray-100 text-gray-600 text-xs uppercase font-semibold" : "py-3 px-20 rounded-sm bg-gray-400 text-gray-600 text-xs uppercase font-semibold"}>
              {this.state.loader ? "submitting..." : "Submit"}
            </button>
          </div>

          {
            this.state?.alert?.msg ? <div className={`${this.state.alert.class} px-4 py-3 relative my-5`} role="alert">
              <span className="block sm:inline">{this.state?.alert?.msg}</span>
            </div> : ''
          }
        </div>
      </form >
    );
  }
}

export default ContactForm;