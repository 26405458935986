import React from "react";
import { useHistory } from "react-router-dom";

export default function FindOutMoreCardComponent({ index, data, highlights, hideFoundOutMoreButton, hideImage, noDarkLight, imageStyle, noJustify }) {

    const history = useHistory();
    const getDarkContainerClass = () => {
        return noDarkLight ? 'bg-white' : 'bg-primary text-white';
    }
    const getLightContainerClass = () => {
        return noDarkLight ? 'bg-white' : 'bg-gray-100 text-gray-500';
    }
    const getDarkBtnClass = () => {
        return noDarkLight ? 'bg-primary text-white' : 'bg-white text-gray-500';
    }
    const getLightBtnClass = () => {
        return 'bg-primary text-white';
    }
    const getJustifyInbetween = () => {
        return noJustify ? 'pt-12' : 'justify-between';
    }

    const isDark = index % 2 ? false : true;
    const containerClass = `flex flex-col px-5 md:px-12 py-4 
    ${isDark ? getDarkContainerClass() : getLightContainerClass()} ${getJustifyInbetween()}`
    const btnClass = `px-8 rounded-sm 
    ${isDark ? getDarkBtnClass() : getLightBtnClass()}`;
    let navigateTo = (_data) => {
        history.push(_data.url);
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100)
    }
    const gridClass = data.imageURL && !hideImage ? 'grid md:grid-cols-2' : 'grid';
    return (
        <div className={gridClass}>
            {data.imageURL && !isDark && !hideImage ? <img src={data.imageURL} alt="" className="w-full h-full"></img> : <></>}
            <div className={containerClass}>
                {
                    data && data.title ?
                        <div className="text-36px pb-4">{data.title}</div>
                        : <></>
                }
                {
                    data && data.description ?
                        <div className="text-18px pb-4">{data.description}</div>
                        : <></>
                }
                {
                    highlights && highlights.length ?
                        <div className="pb-4">
                            <ul className="text-18px list-disc pl-8">
                                {highlights.map((item, i) => <li key={i}>{item}</li>)}
                            </ul>
                        </div>
                        : <></>
                }
                {
                    !hideFoundOutMoreButton ?
                        <div className="pb-6">
                            <button className={btnClass} onClick={() => navigateTo(data)}>
                                FIND OUT MORE
                            </button>
                        </div> : <></>
                }
            </div>
            {data.imageURL && isDark && !hideImage ? <img src={data.imageURL} alt="" className="w-full h-full"></img> : <></>}
        </div >
    );
}
