import React from "react";

export default function ContactInfo({ title, subTitle }) {
  if (!title)
    title = 'Contact team to find out what we can do to support your business';
  if (!subTitle)
    subTitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  return (
    <div className="py-4 px-3 lg:px-8 text-white">
      <div className="text-46px md:text-52px pb-2">{title}</div>
      {/* <div className="text-4xl pb-2">Contact <span className="font-bold">TheHub.ai</span> team to find out what we can do to support your business</div> */}
      <div className="md:text-20px text-16px">{subTitle}</div>
    </div>
  );
}
