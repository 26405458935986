import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
const KEY = process.env.REACT_APP_reCAPTCHA_KEY;


class SectorSubscriptionFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectors: [],
            name: '',
            email_id: '',
            phone: '',
            formDirty: false,
            isCaptchaVerified: false,
            recaptchVisible: true,
            loader: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = async () => {
        let response = await fetch('/fetchSectors');
        response = await response.json();
        if (response && response.data && response.data.length) {
            this.setState({
                sectors: response.data,
                alert_id: JSON.stringify(response.data[0]),
                validation: {}
            })
        }
    }

    validateEmail(emailId) {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailId);
    }

    validatePhoneNumber(phone) {
        return /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/i.test(phone)
    }

    validateForm() {
        this.setState({ formDirty: true, alert: {} });
        let invalid = false;
        let errors = {};
        if (!this.state.name) {
            errors["name"] = "Name is required.";
            invalid = true;
        } else if (this.state.name && this.state.name.length < 3) {
            errors["name"] = "Name should be atleast 3 character long.";
            invalid = true;
        }
        if (!this.state.email_id) {
            errors["email_id"] = "Email is required.";
            invalid = true;
        } else if (this.state.email_id && this.state.email_id.length > 0 && !this.validateEmail(this.state.email_id)) {
            errors["email_id"] = "Email Invalid."
            invalid = true;
        }
        if (this.state.phone && this.state.phone.length > 0 && !this.validatePhoneNumber(this.state.phone)) {
            errors["phone"] = "Phone Invalid."
            invalid = true;
        }
        if (!this.state.isCaptchaVerified) {
            invalid = true;
        }
        this.setState({
            errors
        })
        return invalid;
    }

    handleChange(event) {
        const target = event.target;
        let _value = target.value
        if (target.type === 'checkbox') {
            _value = target.checked
        }
        const name = target.name;
        this.setState({
            [name]: _value
        }, () => {
            if (this.state.formDirty) {
                this.validateForm();
            }
        })
    }

    resetAlert() {
        setTimeout(() => {
            this.setState({ alert: {} })
        }, 10000)
    }

    resetCaptch() {
        setTimeout(() => {
            this.setState({ recaptchVisible: true, isCaptchaVerified: false })
        })
    }

    reloadForm() {
        this.setState({
            name: '',
            email_id: '',
            phone: '',
            alert_id: JSON.stringify(this.state.sectors[0]),
            formDirty: false,
            recaptchVisible: false
        })
        this.resetAlert();
        this.resetCaptch();
    }

    async subscribe(formData) {
        let alert = {
            msg: '',
            class: 'bg-red-100 border border-red-400 text-red-700'
        }
        try {
            let res = await fetch('/createSubscription', {
                method: 'post',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            res = await res.json();
            alert.msg = res.message;
            if (!res.error) {
                alert.class = 'bg-green-100 border border-green-400 text-green-700';
                this.reloadForm();
            } else {
                this.resetCaptch();
            }
            this.setState({ alert: alert, recaptchVisible: false, loader: false });
        } catch (error) {
            alert.msg = error.message
            this.setState({ alert: alert, loader: false })
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ loader: true });
        let response = await fetch('/verifyCaptcha', {
            method: 'post',
            body: JSON.stringify({ token: this.state.captchaToken }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        response = await response.json();
        if (response && response.success) {
            let formInvalid = this.validateForm();
            if (!formInvalid) {
                let formData = { ...this.state };
                delete formData.sectors;
                delete formData.validation;
                this.subscribe(formData);
            }
        } else {
            this.setState({ loader: false });
        }
    }

    onCaptchChange(_token) {
        this.setState({ captcha: true, isCaptchaVerified: _token ? true : false, captchaToken: _token }, () => {
            if (this.state.formDirty) {
                this.validateForm();
            }
        })
    }

    isSubmit() {
        return this.state.isCaptchaVerified;
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} id="sectorSubsForm">
                <div className="px-3 lg:px-8 py-4">
                    <div className="mt-1 block mb-5">
                        <input type="text" className="w-full text-lg border-0" placeholder="Name" value={this.state.name} name="name" onChange={this.handleChange} />
                        <span className="text-red-400 text-xs">{this.state?.errors?.name}</span>
                    </div>

                    <div className="mt-1 block mb-5">
                        <input type="text" className="w-full text-lg border-0" placeholder="Email" value={this.state.email_id} name="email_id" onChange={this.handleChange} />
                        <span className="text-red-400 text-xs">{this.state?.errors?.email_id}</span>
                    </div>

                    <div className="mt-1 block mb-5">
                        <select className="w-full text-lg border-0" name="alert_id" onChange={this.handleChange} value={this.state.alert_id}>
                            {
                                this.state?.sectors?.map((sector) => {
                                    return <option key={sector.id} value={JSON.stringify(sector)}>{sector.title}</option>
                                })
                            }
                        </select>
                    </div>


                    <div className="mt-1 block mb-5">
                        <input type="number" className="w-full text-lg border-0" placeholder="Phone" value={this.state.phone} name="phone" onChange={this.handleChange} />
                        <span className="text-red-400 text-xs">{this.state?.errors?.phone}</span>
                    </div>

                    {
                        this.state.recaptchVisible ?
                            <div className="mt-1 block mb-5">
                                <ReCAPTCHA size="normal"
                                    sitekey={KEY}
                                    onChange={token => { this.onCaptchChange(token) }}
                                />
                                <span className="text-red-400 text-xs">{this.state?.errors?.captcha}</span>
                            </div> : <></>
                    }

                    <div className="pb-5">
                        <button disabled={!this.isSubmit() || this.state.loader} className={this.isSubmit() ? "py-3 px-14 rounded bg-primary text-white" : "py-3 px-14 rounded bg-gray-400 text-white"}>
                            {this.state.loader ? "subscribing..." : "Subscribe"}
                        </button>
                    </div>

                    {
                        this.state?.alert?.msg ? <div className={`${this.state.alert.class} px-4 py-3 relative my-5`} role="alert">
                            <span className="block sm:inline">{this.state?.alert?.msg}</span>
                        </div> : ''
                    }
                </div>
            </form >
        );
    }
}

export default SectorSubscriptionFormComponent;