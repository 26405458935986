import React from 'react';
import FindOutMoreCardComponent from '../components/common/FindOutMoreCardComponent';
import HeroCardComponent from '../components/common/HeroCardComponent';
import SectionTitle from '../components/common/SectionTitle.component';
import ContactContainer from '../components/contact/ContactContainer.component';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
import SectorsContainer from '../components/sectors/SectorsContainer.component';
import ProductsSummaryList from '../product/productsSummary';
class HomePageComponent extends React.Component {
    heroDetails = {
        title: {
            primary: 'Empowering',
            secondary: 'Legal and RegTech',
        },
        subTitle: 'A range of cutting edge applications offering critical insights with lower costs ',
        description: 'Empowering RegTech industry platforms with direct delivery of cost effective regulatory information and compliance solutions.',
        imageURL: "/assets/hero.png",
        marqueURL : "/assets/cube.png"
    }
    contactDetails = {
        subTitle: "By combining artificial intelligence-powered technology and vector tagging, we have created a complete regulatory content monitoring tool to empower your organisation.",
        title: "If you would like to request a demo or find out more about The Hub, please get in touch."
    }

    render() {
        return <div>
            <a class="cubeProp" href="https://www.cube.global/about-cube/news/cube-acquires-the-hub-to-extend-further-its-automated-regulatory-intelligence-ari-technology/" target="_blank">
                <img src={this.heroDetails.marqueURL} />
            </a>
            <Navbar />
            <HeroCardComponent
                title={this.heroDetails.title.primary}
                titleSecondary={this.heroDetails.title.secondary}
                subTitle={this.heroDetails.subTitle}
                highlights={this.heroDetails.highlights}
                bgImageUrl={this.heroDetails.imageURL}
                description={this.heroDetails.description}
            />
            <div>
                <SectionTitle title={"Products"} />
                <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
                    {
                        ProductsSummaryList.map((_feature, index) => {
                            return <FindOutMoreCardComponent key={_feature.title} data={_feature}
                                index={index} highlights={_feature.highlights} hideImage={true} />
                        })
                    }
                </div>
            </div>

            <SectorsContainer />
            <ContactContainer contactDetails={this.contactDetails} />
            <FooterContainer />
        </div>
    }
}
export default HomePageComponent;