import React from "react";
import FooterAddress from "./FooterAddress.component";
import FooterLogo from "./FooterLogo.component";
import FooterNavigation from "./FooterNavigation.component";
import FooterSocialInfo from "./FooterSocialInfo.component";

export default function FooterContainer() {
  return (
    <section className="flex flex-wrap px-12 py-12 pb-36 bg-white text-gray-400 text-xl footer">
      <div className="flex-none py-3">
        <FooterLogo />
      </div>
      <div className="flex-none py-3 px-2">
        <FooterAddress />
      </div>
      <div className="flex-none md:px-3 lg:px-10 py-3">
        <FooterNavigation />
      </div>
      <div className="flex flex-grow xl:px-10 py-3">
        <FooterSocialInfo />
      </div>
    </section>
  );
}
