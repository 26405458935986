import React from 'react';
import FooterContainer from '../components/footer/FooterContainer.component';
import Navbar from '../components/navbar/Navbar.component';
class DataProtectionPageComponent extends React.Component {
    render() {
        return <div>
            <Navbar />
            <section className="bg-gray-100 text-green-900 relative hero_sec">
                <div
                    className="hero-image bg-right-bottom bg-cover flex text-white">
                    <div className="relative container md:px-8 px-3 pt-4 pb-8 flex items-end z-10">
                        <div className="content float-left pt-4 pb-8 md:px-5 my-5">
                            <div className="heading mb-3 text-46px md:text-52px text-gray-500">Privacy Policy&nbsp;
                            </div>

                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                1.PURPOSE OF THIS NOTICE
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    This notice describes how we collect and use personal data about you,
                                    in accordance with the General Data Protection Regulation (GDPR), the Data Protection Act and any other national implementing laws, regulations and secondary legislation, as amended or updated from time to time, in the UK (‘Data Protection Legislation’).
                                </p>
                                <p className="pb-2">
                                    Please read the following carefully to understand our practices regarding your personal
                                    data and how we will treat it.
                                </p>
                            </div>

                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                2.ABOUT US
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    The Hub Artificial Intelligence Ltd. (collectively all the products and services which we market at www.thehub.ai) is “an Information technology service company”.
                                    We are registered in England and Wales as a limited company under
                                    number: 08724740 and our registered office is at Hounslow Business Park Unit 6,
                                    Alice Way, Hounslow, Middlesex, TW3 3UD.
                                </p>
                                <p className="pb-2">
                                    For the purpose of the Data Protection Legislation and this notice,
                                    we are the ‘data controller’.
                                    This means that we are responsible for deciding how we hold and use personal data about you.
                                    We are required under the Data Protection Legislation to notify you of the information contained in this privacy notice.
                                </p>
                                <p className="pb-2">
                                    We have appointed a data protection manager.
                                    Our data protection manager is our Data Protection Point of Contact and is responsible for
                                    assisting with enquiries in relation to this privacy notice or our treatment of
                                    your personal data.
                                    Should you wish to contact our Data Protection Point of Contact
                                    you can do so using the contact details noted at paragraph 12 (Contact Us),
                                    below.
                                </p>
                            </div>

                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                3.HOW WE MAY COLLECT YOUR PERSONAL DATA
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    We obtain personal data about you, for example, when:
                                </p>
                                <ul className="md:text-18px text-14px list-disc pl-8">
                                    <li className="pb-2">
                                        you request a proposal from us in respect of the services we provide;
                                    </li>
                                    <li className="pb-2">
                                        you or your employer or our clients engages us to provide our services and also during the
                                        provision of those services; you contact us by email,
                                        telephone or post (for example when you have a query about our services); or
                                    </li>
                                    <li className="pb-4">
                                        from third parties and/or publicly available resources
                                        (for example, from your employer or from Companies House).
                                    </li>
                                </ul>
                            </div>

                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                4.THE KIND OF INFORMATION WE HOLD ABOUT YOU
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    The information we hold about you may include the following:
                                </p>
                                <ul className="md:text-18px text-14px list-disc pl-8">
                                    <li className="pb-2">
                                        your personal details (such as your name and/or address);
                                    </li>
                                    <li className="pb-2">
                                        details of contact we have had with you in relation to the provision,
                                        or the proposed provision, of our services;
                                    </li>
                                    <li className="pb-4">
                                        details of any services you have received from us;
                                    </li>
                                    <li className="pb-2">
                                        our correspondence and communications with you;
                                    </li>
                                    <li className="pb-2">
                                        information about any complaints and enquiries you make to us;
                                    </li>
                                    <li className="pb-2">
                                        information from research, surveys, and marketing activities;
                                    </li>
                                    <li className="pb-4">
                                        Information we receive from other sources,
                                        such as publicly available information,
                                        information provided by your employer or our clients or information from our member network firms.
                                    </li>
                                </ul>
                            </div>

                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">5.HOW WE USE PERSONAL DATA WE HOLD ABOUT YOU</div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <ul className="md:text-18px text-14px list-disc pl-8">
                                    <li className="pb-2">
                                        We may process your personal data for purposes necessary for the performance of our
                                        contract with you or your employer or our clients and to comply with our legal obligations.
                                    </li>
                                    <li className="pb-2">
                                        We may process your personal data for the purposes necessary
                                        for the performance of our contract with our clients.
                                        This may include processing your personal data where you are an employee,
                                        subcontractor, supplier or customer of our client.
                                    </li>
                                    <li className="pb-2">
                                        We may process your personal data for the purposes of our own legitimate interests provided that
                                        those interests do not override any of your own interests,
                                        rights and freedoms which require the protection of personal data.
                                        This includes processing for marketing, business development,
                                        statistical and management purposes.
                                        We may process your personal data for certain additional purposes with your consent,
                                        and in these limited circumstances where your consent is required for the processing of
                                        your personal data then you have the right to withdraw your consent to processing for such specific purposes.
                                    </li>
                                </ul>
                                <p className="pb-4">
                                    Please note that we may process your personal data for more than one lawful basis
                                    depending on the specific purpose for which we are using your data.
                                </p>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                Situations in which we will use your personal data
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    We may use your personal data in order to:
                                </p>
                                <ul className="md:text-18px text-14px list-disc pl-8">
                                    <li className="pb-2">
                                        carry out our obligations arising from any agreements
                                        entered into between you or your employer or our clients and us
                                        (which will most usually be for the provision of our services);
                                    </li>
                                    <li className="pb-2">
                                        carry out our obligations arising from any agreements entered into
                                        between our clients and us
                                        (which will most usually be for the provision of our services)
                                        where you may be a subcontractor, supplier or customer of our client;
                                    </li>
                                    <li className="pb-2">
                                        provide you with information related to our services and our events and activities
                                        that you request from us or which we feel may interest you,
                                        provided you have consented to be contacted for such purposes;
                                    </li>
                                    <li className="pb-2">
                                        seek your thoughts and opinions on the services we provide;and
                                    </li>
                                    <li className="pb-2">
                                        notify you about any changes to our services
                                    </li>
                                    <li className="pb-2">
                                        In some circumstances we may anonymise or pseudonymise the personal data so
                                        that it can no longer be associated with you, in which case we may use it without further
                                        notice to you.
                                    </li>
                                    <li className="pb-4">
                                        If you refuse to provide us with certain information when requested,
                                        we may not be able to perform the contract we have entered into with you,
                                        Alternatively, we may be unable to comply with our legal or regulatory obligations.
                                    </li>
                                    <li className="pb-4">
                                        We may also process your personal data without your knowledge or consent,
                                        in accordance with this notice, where we are legally required or permitted to do so.
                                    </li>
                                </ul>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                Data retention
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <ul className="md:text-18px text-14px list-disc pl-8">
                                    <li className="pb-2">
                                        We will only retain your personal data for as long as is necessary to fulfil the purposes
                                        for which it is collected.
                                    </li>
                                    <li className="pb-2">
                                        When assessing what retention period is appropriate for your personal data,
                                        we take into consideration:
                                    </li>
                                    <li className="pb-2">
                                        the requirements of our business and the services provided;
                                    </li>
                                    <li className="pb-4">
                                        any statutory or legal obligations; the purposes for which we
                                        originally collected the personal data; the lawful grounds on which we based our processing;
                                    </li>
                                    <li className="pb-2">
                                        the types of personal data we have collected;
                                    </li>
                                    <li className="pb-2">
                                        the amount and categories of your personal data;
                                        and
                                    </li>
                                    <li className="pb-2">
                                        whether the purpose of the processing could reasonably be fulfilled by other means.
                                    </li>
                                </ul>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                Change of purpose
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    Where we need to use your personal data for another reason,
                                    other than for the purpose for which we collected it,
                                    we will only use your personal data where that reason is compatible with the original purpose.
                                </p>
                                <p className="pb-2">
                                    Should it be necessary to use your personal data for a new purpose,
                                    we will notify you and communicate the legal basis which allows us to do so before starting any new processing.
                                </p>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                6.DATA SHARING
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    Why might you share my personal data with third parties?
                                </p>
                                <ul className="md:text-18px text-14px list-disc pl-8">
                                    <li className="pb-2">
                                        We will share your personal data with third parties where we are required by law,
                                        where it is necessary to administer the relationship between us or
                                        where we have another legitimate interest in doing so.
                                    </li>
                                </ul>
                                <p className="pb-2">
                                    Which third-party service providers process my personal data?
                                </p>
                                <ul className="md:text-18px text-14px list-disc pl-8">
                                    <li className="pb-2">
                                        “Third parties” includes third-party service providers and other entities within our
                                        company or the members of our firm’s network.
                                        The following activities are carried out by third-party service providers:
                                        Bookkeeping and accountancy services,
                                        IT and cloud services, professional advisory services and administration services.
                                        All of our third-party service providers are required to take commercially
                                        reasonable and appropriate security measures to protect your personal data.
                                        We only permit our third-party service providers to process your personal data for
                                        specified purposes and
                                        in accordance with our instructions.
                                    </li>
                                </ul>
                                <p className="pb-2">
                                    What about other third parties?
                                </p>
                                <ul className="md:text-18px text-14px list-disc pl-8">
                                    <li className="pb-2">
                                        We may share your personal data with other third parties,
                                        for example in the context of the possible sale or restructuring of the business.
                                        We may also need to share your personal data with a regulator or to otherwise
                                        comply with the law. Occasionally your data may be handled by staff
                                        working outside of the EU, performing repair work to your data.
                                        In these circumstance, they securely access the data remotely on our server.
                                        This activity is necessary for the performance of a contract made in your interest.
                                    </li>
                                </ul>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                7.TRANSFERRING PERSONAL DATA OUTSIDE THE EUROPEAN ECONOMIC AREA (EEA)
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    We will not transfer the personal data we collect about you outside of the EEA.
                                </p>
                                <p className="pb-2">
                                    Should you require further information about this protective measure[s],
                                    please contact us using the contact details outlined below.
                                </p>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                8.DATA SECURITY
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    We have put in place commercially reasonable and
                                    appropriate security measures to prevent your personal data from being accidentally lost,
                                    used or accessed in an unauthorised way, altered or disclosed.
                                    In addition, we limit access to your personal data to those employees, agents, contractors and
                                    other third parties who have a business need to know. They will only process your personal data on our
                                    instructions and they are subject to a duty of confidentiality.
                                </p>
                                <p className="pb-2">
                                    We have put in place procedures to deal with any suspected data security breach and will notify you and
                                    any applicable regulator of a suspected breach where we are legally required to do so.
                                </p>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                9.RIGHTS OF ACCESS, CORRECTION, ERASURE, AND RESTRICTION
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                Your duty to inform us of changes
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    It is important that the personal data we hold about you is accurate and current.
                                    Should your personal information change,
                                    please notify us of any changes of which we need to be made aware by contacting us,
                                    using the contact details below.
                                </p>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                Your rights in connection with personal data
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    Under certain circumstances, by law you have the right to:
                                </p>
                                <p className="pb-2">
                                    Request access to your personal data. This enables you to
                                    receive details of the personal data we hold about you and to
                                    check that we are processing it lawfully.
                                </p>
                                <p className="pb-2">
                                    Request correction of the personal data that we hold about you.
                                </p>
                                <p className="pb-2">
                                    Request erasure of your personal data.
                                    This enables you to ask us to delete or remove personal data
                                    where there is no good reason for us continuing to process it.
                                    You also have the right to ask us to delete or remove your personal data
                                    where you have exercised your right to object to processing (see below).
                                </p>
                                <p className="pb-2">
                                    Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this basis. You also have the right to object where we are processing your personal information for direct marketing purposes.
                                </p>
                                <p className="pb-2">
                                    Request the restriction of processing of your personal data.
                                    This enables you to ask us to suspend the processing of personal data about you,
                                    for example if you want us to establish its accuracy or the reason for processing it.
                                </p>
                                <p className="pb-2">
                                    Request the transfer of your personal data to you or another data controller
                                    if the processing is based on consent,
                                    carried out by automated means and this is technically feasible.
                                </p>
                                <p className="pb-2">
                                    If you want to exercise any of the above rights,
                                    please email our data protection point of contact admin@thehub.ai
                                </p>
                                <p className="pb-2">
                                    You will not have to pay a fee to access your personal data
                                    (or to exercise any of the other rights).
                                    However, we may charge a reasonable fee if your request
                                    for access is clearly unfounded or excessive. Alternatively,
                                    we may refuse to comply with the request in such circumstances.
                                </p>
                                <p className="pb-2">
                                    We may need to request specific information from you to help us
                                    confirm your identity and ensure your right to access the information
                                    (or to exercise any of your other rights).
                                    This is another appropriate security measure to ensure that personal information
                                    is not disclosed to any person who has no right to receive it.
                                </p>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">10.RIGHT TO WITHDRAW CONSENTM</div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    In the limited circumstances where you may have provided your consent to the collection,
                                    processing and transfer of your personal data for a specific purpose
                                    (for example, in relation to direct marketing that you have indicated you would like to receive from us),
                                    you have the right to withdraw your consent for that specific processing at any time.
                                    To withdraw your consent, please email our data protection point of contact admin@thehub.ai.
                                </p>
                                <p className="pb-2">
                                    Once we have received notification that you have withdrawn your consent,
                                    we will no longer process your personal information (personal data)
                                    for the purpose or purposes you originally agreed to,
                                    unless we have another legitimate basis for doing so in law.
                                </p>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">
                                11.CHANGES TO THIS NOTICE
                            </div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    Any changes we may make to our privacy notice in the future will be updated on our website
                                    at: http://www.thehub.ai.
                                </p>
                                <p className="pb-2">
                                    This privacy notice was last updated on 25 May 2018.
                                </p>
                            </div>
                            <div className="text leading-normal font-semibold sm:block lg:w-1/2 md:text-20px text-16px pb-2 text-gray-500">12.CONTACT US</div>
                            <div className="text leading-normal sm:block  md:text-18px text-14px pb-2 text-gray-500">
                                <p className="pb-2">
                                    If you have any questions regarding this notice or if you would like to
                                    speak to us about the manner in which we process your personal data,
                                    please contact our data protection team on +44 20 7097 4753 or admin@thehub.ai
                                </p>
                                <p className="pb-2">
                                    You also have the right to make a complaint to the Information Commissioner's Office (ICO),
                                    the UK supervisory authority for data protection issues, at any time. The ICO’s contact details are as follows:
                                </p>
                                <p className="pb-2">
                                    Information Commissioner's Office:- Wycliffe House, Water Lane, Wilmslow Cheshire
                                </p>
                                <p className="pb-2">
                                    SK9 5AF. Telephone - 0303 123 1113 (local rate) or 01625 545 745
                                </p>
                                <p className="pb-2">
                                    Website - https://ico.org.uk/concerns
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterContainer />
        </div>
    }
}
export default DataProtectionPageComponent;