import React from "react";
import SectionTitle from "../common/SectionTitle.component";
import SectorListContainer from "./SectorListContainer.component";

export default function SectorsContainer() {
  return (
    <>
    <section className="flex flex-col">
      <SectionTitle title={"Our Sectors"} />
      <SectorListContainer />
    </section>
    </>
  );
}
